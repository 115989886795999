import { withPrefix } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layouts/layout';
import BreadCrumb from '../components/ProductMain/BreadCrumb';
import ProductMain from '../components/ProductMain/ProductMain';

const facewash = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rose And Gold Face Wash Cleanses – Exfoliates - Brightens</title>
        <meta name="facebook-domain-verification" content="7zavsnqe2ti2yx2faj448nyhtkrxg5" />
        <meta name="title" content="Rose And Gold Face Wash Cleanses – Exfoliates - Brightens" />
        <meta
          name="description"
          content="Rose And Gold Face Wash Cleanses and Brightens you face. It is fortified with potent ingredients like Licor"
        />
        <meta
          name="keywords"
          content="Rose And Gold Face Wash, Cleansing Face Wash, Skincare, Face, Face Wash, Facecare"
        />
        {/* Global site tag (gtag.js) - Google Analytics  */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-1LB4M0W7V9" />
        <script src={withPrefix('js/script.js')} type="text/javascript" />
      </Helmet>
      <Layout>
        <BreadCrumb productSelected="facewash" />
        <ProductMain productSelected="facewash" />
      </Layout>
    </>
  );
};

export default facewash;
